import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";

import { Container, Wrapper, Row } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { CustomerCard, CallToAction } from "../components/site";

const CustomerList = styled(Row)`
  --item-gap: 30px;
  align-items: stretch;

  width: calc(100% + var(--item-gap));

  > div {
    width: calc((100% / 2) - var(--item-gap));
    margin-right: var(--item-gap);
    margin-bottom: var(--item-gap);

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
      margin-right: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const CaseStudies = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCustomerStory(sort: { fields: priority, order: DESC }) {
        edges {
          node {
            companyName
            url
            priority
            industry
            heroQuote
            showVideoIcon
            banner {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
            logo {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo
        title="Customer Stories | Droppah Customers"
        description="Meet companies doing rostering better. Hear what these clever companies have to say about how Droppah is helping their business."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={80}>
          <div className="-textCenter">
            <h1>Customer Stories</h1>
            <h4>
              Hear what these clever companies have to say about how Droppah is
              helping their business.
            </h4>
          </div>
          <CustomerList isWrap justify="flex-start">
            {data.allContentfulCustomerStory.edges.map((edge, i) => {
              return (
                <CustomerCard
                  key={i}
                  slug={edge.node.url}
                  banner={edge.node.banner.gatsbyImageData}
                  logo={edge.node.logo.gatsbyImageData}
                  quote={edge.node.heroQuote}
                  industry={edge.node.industry}
                  company={edge.node.companyName}
                  showVideoIcon={edge.node.showVideoIcon}
                />
              );
            })}
          </CustomerList>
          <h4 className="-textCenter" css={{ marginBottom: "25px" }}>
            Do you have a Droppah story to tell? Get in touch by emailing{" "}
            <a href="mailto:hello@droppah.com">hello@droppah.com</a>.
          </h4>
        </Wrapper>
      </Container>
      <CallToAction />
    </Layout>
  );
};

export default CaseStudies;
